import React from "react"
import "./LeadMagnetBar.css"

const LeadMagnetBar = () => (
  <div className="lead-magnet-bar">
    <a href="https://ebook.welearncode.com">
      Get my free ebook about all the things I wish I knew when I was learning
      to code!
    </a>{" "}
    >>
  </div>
)

export default LeadMagnetBar
