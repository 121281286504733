import React from 'react'
import { Link } from 'gatsby'
import './IntroSection.css'

const IntroSection = () => (
  <section className='intro'>
    <div className='intro-content'>
      <h1>We Learn Code</h1>
      <p>
        <Link to='/about'>Ali’s</Link> friendly guides to your biggest coding
        questions.
      </p>
    </div>
  </section>
)

export default IntroSection
