import React from 'react'
import { Link } from 'gatsby'
import './FeaturedPosts.css'

const FeaturedPosts = ({ posts, heading, link }) => {
  return (
    <section>
      <h2 style={{ textAlign: 'center', marginBottom: '10px' }}><Link to={link} style={{ color: 'black' }}>{heading}</Link></h2>
      <div className='featured-posts'>
        {posts.edges.map(post => (
          <div key={post.node.fields.slug} className='featured-post' style={{ marginTop: '10px' }}>
            <Link to={post.node.fields.slug}>
              <h2>{post.node.frontmatter.title}</h2>
            </Link>
            <span>{post.node.frontmatter.date}</span>
            <Link to={post.node.fields.slug} className='read-more'>
            Read More
            </Link>
          </div>
        ))}
      </div>
      <Link to={link} className='read-more'>View All</Link>
    </section>
  )
}

export default FeaturedPosts
