import React from "react"
import { Link } from "gatsby"

import "./Bio.css"

const Bio = () => (
  <section className="bio">
    <img src="/dots.svg" alt="" className="dots" />
    <div className="bio-content">
      <h2>Hi! I'm Ali</h2>
      <p>
        I’m a software engineer and teacher. I blog about the things I wish I
        knew when I was learning to code. You'll find technical tutorials,
        career advice, and more all right here!
      </p>
      <Link to="/about" className="learn-more">
        Learn More
      </Link>
    </div>
    <img src="/selfie.jpg" alt="Ali Spittel" className="selfie" />
  </section>
)

export default Bio
