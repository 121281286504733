import React from 'react'

import Layout from '../components/Layout'
import IntroSection from '../components/IntroSection'
import LeadMagnetBar from '../components/LeadMagnetBar'
import FeaturedPosts from '../components/FeaturedPosts'
import Bio from '../components/Bio'
import SEO from '../components/Seo'

const BlogIndex = (props) => {
  console.log(props)
  return (
    <Layout location={props.location} title='We Learn Code'>
      <SEO title='Home' pathname='home' />
      <IntroSection />
      <LeadMagnetBar />
      <div className='container' style={{ marginTop: '0px' }}>
        <FeaturedPosts posts={props.data.featuredPosts} heading='Recent Posts' link='/recent-posts' />
        <Bio />
        <FeaturedPosts posts={props.data.beginnersGuides} heading="Beginner's Guides" link='/beginners-guides' />
        <FeaturedPosts posts={props.data.advice} heading='Career Advice' link='/advice' />
        <FeaturedPosts posts={props.data.tutorials} heading='Tutorials' link='/tutorials' />
        <FeaturedPosts posts={props.data.quickTips} heading='Quick Tips' link='/quick-tips' />
        <FeaturedPosts posts={props.data.favorites} heading='Favorites' link='/favorites' />
      </div>
    </Layout>
  )
}

export default BlogIndex

export const PageQuery = graphql`
  query AllBlogPosts {
    site {
      siteMetadata {
        title
      }
    }
    featuredPosts: allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 3) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
          }
        }
      }
    }
    quickTips: allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 3, filter: { frontmatter: { category: { eq: "Quick Tips" } }}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
          }
        }
      }
    }
    tutorials: allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 3, filter: { frontmatter: { category: { eq: "Tutorials" } }}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
          }
        }
      }
    }
    advice: allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 3, filter: { frontmatter: { category: { eq: "Advice" } }}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
          }
        }
      }
    }
    beginnersGuides: allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 3, filter: { frontmatter: { category: { eq: "Beginner's Guides" } }}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
          }
        }
      }
    }
    favorites: allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 3, filter: { frontmatter: { category: { eq: "Favorites" } }}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
          }
        }
      }
    }
}
`
